.modal-90w{
    position: fixed;
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 0;
    min-height: 0;
    width: 100%;
    /* z-index: 1000; */
}

.page-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* flip.css */
.flipbook-container {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .flipbook-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
} */

